import React from "react";
import './donate.styles.scss';

class Donate extends React.Component {
    render() {
        return (
            <div className="donateContainer">
                <h1>Donate</h1>
            </div>
        );
    }
}

export default Donate;