import React from "react";
import './auction.styles.scss';

class Auction extends React.Component {
    render() {
        return(
            <div className="auctionContainer">
                <h1>Auction</h1>
            </div>
        );
    }
}

export default Auction;